var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info)?_c('el-main',[_c('div',{staticClass:"title"},[_vm._v("预约信息")]),_c('div',{staticStyle:{"background":"#F8F9FA","margin-top":"20px","text-align":"center","padding-bottom":"30px"}},[_c('time-steps',{attrs:{"list":_vm.time_list,"active":_vm.time_active}}),_c('div',{staticClass:"status"},[_vm._v("预约状态："+_vm._s(_vm.status))]),(_vm.info.status == 10)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.toshop}},[_vm._v("确认到店")]):_vm._e(),(_vm.info.status == 20)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.toCashier}},[_vm._v("开单收银")]):_vm._e(),(_vm.info.status == 30)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.toDiagnosisForm(0)}}},[_vm._v("添加诊断")]):_vm._e(),(_vm.info.status == 40)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toDiagnosisForm(1)}}},[_vm._v("查看诊断")]):_vm._e(),(_vm.info.status == 30 || _vm.info.status == 40)?_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.toOrderDetail}},[_vm._v("订单详情")]):_vm._e()],1),_c('div',{staticClass:"title2"},[_vm._v("预约人信息")]),_c('el-table',{attrs:{"data":_vm.tableData}},[_c('template',{slot:"empty"},[_c('No')],1),(_vm.tableData[0].openid)?_c('el-table-column',{attrs:{"label":"预约用户"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row"},[_c('el-image',{staticClass:"avatar",attrs:{"src":row.avatar}}),_c('div',[_vm._v(_vm._s(row.nick_name))])],1)]}}],null,false,343934945)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"name","label":"下单人","align":"center"}}),_c('el-table-column',{attrs:{"prop":"mobile","label":"预约电话","align":"center"}}),(_vm.tableData[0].oprator_name)?_c('el-table-column',{attrs:{"prop":"oprator_name","label":"填写预约账号","align":"center"}}):_vm._e(),_c('el-table-column',{attrs:{"label":"服务主图","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticClass:"images",attrs:{"src":row.service[0].images}})]}}],null,false,958523343)}),_c('el-table-column',{attrs:{"label":"服务名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.service[0].service_name))]}}],null,false,3118214111)}),(_vm.tableData[0].service[0].service_spec_id)?_c('el-table-column',{attrs:{"label":"规格","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.service[0].service_spec_name))]}}],null,false,3025696453)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"staff.service_name","label":"服务人员","align":"center"}}),_c('el-table-column',{attrs:{"label":"支付方式","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.pay_type==1?'在线支付':'到店支付'))]}}],null,false,1751539572)})],2),(_vm.info.order_person_detail)?_c('div',{staticClass:"others"},_vm._l((_vm.info.order_person_detail),function(item,index){return _c('div',{key:index},[_c('span',{staticStyle:{"margin-right":"40px"}},[_vm._v("预约人"+_vm._s(index+2)+"："+_vm._s(item.name))]),_c('span',{staticStyle:{"margin-right":"120px"}},[_vm._v("联系电话："+_vm._s(item.mobile))])])}),0):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }